import Accordion from '../../ui/ui-accordion/ui-accordion.js';
import Tabs from '../../ui/ui-tabs/ui-tabs.js'


const tabsFAQ = new Tabs({
   visible: '.faq-tabs .btn_tab',
   collapse: '.faq-content',
})

// TODO оставляем несколько видимых элементов в faq аккордионе

const faqMore = document.querySelectorAll('.faq-content .faq-more')
const faqAccordions = document.querySelectorAll('.faq-content .ui-accordion')

// Добавляем класс hide элементам с индексом больше 4
faqAccordions.forEach(accordion => {
   let accItems = accordion.querySelectorAll('.ui-accordion__item')
   let parent = accordion.parentNode;
   let moreBtn = parent.querySelector('.faq-more');

   if (accItems.length <= 5)  moreBtn.style.display = 'none';

   accItems.forEach((item, index) => {
      if (index > 4) item.classList.add('hide')
   })
})

//  Показываем элементы при клике на стрелку "показать еще"
faqMore.forEach(btnItem => {
   btnItem.addEventListener('click', () => toggleVisibilityItems(btnItem))
})

function toggleVisibilityItems (currentBtn) {
   currentBtn.classList.toggle('active')
   let parent = currentBtn.parentNode
   parent.querySelectorAll('.ui-accordion__item').forEach((item, index) => {
      if (index > 4) {
         currentBtn.classList.contains('active') ? item.classList.remove('hide') : item.classList.add('hide')
      }
   })
}
import sendForm from '../../../../js/module/sendForm/sendForm.js';

const submitBtn = document.querySelector('[type="submit"]')
const popupForm = document.querySelector('#form .form-fields')
const formTitle = document.querySelector('.form-wrap .form-title')

document.querySelectorAll('.btn_popup').forEach(btn => {
   btn.addEventListener('click', function (e) {

      let btnAttrForm = this.dataset.form
      let currentFormName = this.dataset.name

      popupForm.innerHTML = ''
      formTitle.innerHTML = currentFormName
      

      renderForms(btnAttrForm, currentFormName)
   })
})

function renderForms (formType, title) {
   const contactFields = `
      <input type="hidden" name="subject" value="${title}">

      <div class="form-item">
         <input type="text" name="user_name" inputmode="text" autocomplete="given-name" placeholder="Имя*" class="field" data-required data-type="name">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <input type="tel" name="user_tel" inputmode="tel" autocomplete="tel" placeholder="Телефон*" class="field" data-required data-type="tel">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <input type="email"  name="user_email" inputmode="email" autocomplete="email" placeholder="Email" class="field" data-type="email">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <textarea name="user_message" placeholder="Сообщение*" class="field" data-required data-type="message"></textarea>
         <span class="error-message"></span>
      </div>
   `

   const callFields = `
      <input type="hidden" name="subject" value="${title}">

      <div class="form-item">
         <input type="text" name="user_name" inputmode="text" autocomplete="given-name" placeholder="Имя*" class="field" data-required data-type="name">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <input type="tel" name="user_tel" inputmode="tel" autocomplete="tel" placeholder="Телефон*" class="field" data-required data-type="tel">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <textarea name="user_message" placeholder="Сообщение" class="field" data-type="message"></textarea>
         <span class="error-message"></span>
      </div>
   `

   const requestFields = `
      <input type="hidden" name="subject" value="${title}">

      <div class="form-item">
         <input type="text" name="user_name" inputmode="text" autocomplete="given-name" placeholder="Имя*" class="field" data-required data-type="name">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <input type="tel" name="user_tel" inputmode="tel" autocomplete="tel" placeholder="Телефон*" class="field" data-required data-type="tel">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <input type="email"  name="user_email" inputmode="email" autocomplete="email" placeholder="Email" class="field" data-type="email">
         <span class="error-message"></span>
      </div>

      <div class="form-item">
         <textarea name="user_message" placeholder="Сообщение" class="field" data-type="message"></textarea>
         <span class="error-message"></span>
      </div>
   `

   switch (formType) {
      case 'contact':
         popupForm.insertAdjacentHTML('afterbegin', contactFields)
         break;
         
      case 'call':
         popupForm.insertAdjacentHTML('afterbegin', callFields)
         break;

      case 'request': 
         popupForm.insertAdjacentHTML('afterbegin', requestFields)
         break;
      default: 
         break;
   }
}


submitBtn.addEventListener('click', function () {
   grecaptcha.ready(function () {
      // ключ сайта
      grecaptcha.execute('6LfMUxMqAAAAAEeYqqVHgTyG6BnAStRSBwBfptOx', { action: 'submit' }).then(function (token) {
         onSubmit(token);
      });
   });
});

async function onSubmit(token) {
   const form = document.getElementById('form');
   await sendForm(form, token);
}

document.getElementById('form').addEventListener('submit', function (e) {
   e.preventDefault();
});

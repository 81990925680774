export default class Notification {
   constructor ({ popup = '#notification', open = null, close = '#notification .ui-close', closeItem = '#notification .note-btn', overlay = '#notification .popup-overlay' }) {
      this.$popup = document.querySelector(popup)
      this.$openBtn = document.querySelectorAll(open)
      this.$closeBtn = document.querySelector(close)
      this.$closeItem = document.querySelectorAll(closeItem)
      this.$overlay = document.querySelector(overlay)

      this.#setup()  
   }

   #setup = () => {
      this.$overlay?.addEventListener('click', this.onClose)
      this.$closeBtn?.addEventListener('click', this.onClose)
      this.$closeItem?.forEach(el => {
         el.addEventListener('click', () => {
            this.onClose()
         })
      })
      document.body.addEventListener('keydown', (e) => {
         if (e.keyCode == 27) this.onClose()
      })
   }

   onOpen = () => {
      this.$popup.classList.add('active')
		this.$overlay?.classList.add('active')
      document.body.classList.add('no-scroll')
   }

   onClose = () => {
      this.$popup?.classList.remove('active')
		this.$overlay?.classList.remove('active')
      document.body.classList.remove('no-scroll')
   }
}
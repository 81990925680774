// TODO Проверка touch screen
function isTouchScreen() {
   return ( 'ontouchstart' in window ) || 
          ( navigator.maxTouchPoints > 0 ) ||
          ( navigator.msMaxTouchPoints > 0 );
}

const startItems = document.querySelectorAll('.start-item')

startItems.forEach(item => {
   if (isTouchScreen()) {
      item.addEventListener('click', () => {
         item.classList.toggle('hover')
      })
   }
})


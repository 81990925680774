import '../components/ui/preloader/preloader.js';
// import '../components/header/header.js';
import '../components/swimming-chat/swimming-chat.js';
import '../components/popups/popup-form/popup-form.js';
import '../components/popups/popup-form/form/form.js';
import '../components/mobile-menu/mobile-menu.js';
import '../components/screen/faq-screen/faq-screen.js';
import Accordion from '../components/ui/ui-accordion/ui-accordion.js';



// TODO Sticky elements
window.addEventListener('load', () => {
   fadeInNavOnScroll('header.header-sticky', 150)
})

window.addEventListener('scroll', () => {
   fadeInNavOnScroll('header.header-sticky', 150)
})


function fadeInNavOnScroll(elements, distance) {
   let items = document?.querySelectorAll(elements)

   for (let i = 0; i < items.length; i++) {
      if (window.scrollY >= distance) 
         items[i].classList.add('active')
      
      if (window.scrollY <= 150)
         items[i].classList.remove('active')
   }
}



// TODO Инициализация аккордионов
new Accordion({
   visible: '.ui-accordion__item .item-title',
   collapse: '.ui-accordion__item .item-content'
})
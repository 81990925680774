import $ from 'jquery';

export default function Accordion ({ visible, collapse, showAll = false}) {
   
   !showAll && $(collapse).hide()
   
   $(visible).on('click', function () {
      var $this = $(this);
      var $content = $this.next(collapse);
      $(collapse).not($content).slideUp();
      $(visible).removeClass('active');
      $this.addClass('active');
      $content.slideToggle();
  });
}
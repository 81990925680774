const chat = document.querySelector('.swimming-chat')
const chatOverlay = document.querySelector('.overlay-chat')
const chatIcon = document.querySelector('.swimming-stock i')

chat.addEventListener('click', function () {
   this.classList.toggle('active')
   chatOverlay.classList.toggle('active')

   if (this.classList.contains('active')) 
      chatIcon.className = chatIcon.className.replace('i-chat', 'i-close')
   else 
      chatIcon.className = chatIcon.className.replace('i-close', 'i-chat')
   
})

chatOverlay.addEventListener('click', function () {
   chat.classList.remove('active')
   this.classList.remove('active')
   chatIcon.className = chatIcon.className.replace('i-close', 'i-chat')
})


import openPopup from '../popup.js';

// TODO Рендер отзывов при открытии попапа с отзывами
const dataReviews = [
   {
      id: 1,
      author: 'Dark Fit',
      date: 'Февраль 24, 2024',
      text: 'IQ LABS - единственная компания которой я доверяю. Всем доволен, всегда помогут, объяснят, расскажут что к чему.',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 2,
      author: 'Magik Ghost',
      date: 'Январь 12, 2024',
      text: 'Мне этот пул посоветовал кореш, он себе ставил их прошивку. В итоге я занимаюсь майнингом только на этом пуле, IQL-прошивки себе тоже поставил на все машинки и уже ставл ВИП клиентом!!! Прошивки, кстати реально топовые, спасибо!',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 3,
      author: 'Святой',
      date: 'Февраль 07, 2024',
      text: 'Уровень выше среднего... Сравнивал их с другим пулом, на котором раньше сидел, не буду называть коненчо его здесь. В итоге у меня прибыль здесь всегда на 3% выше. Для моих мощностей, это ахереть какая разница. Очень радует, что ребята честные',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 4,
      author: 'Толик Садовый',
      date: 'Март 01, 2024',
      text: 'Смотрю по факту, машинки выдают 340 Тх, на пуле выплаты за сутки показывает 337 Тх. Делайте выводы сами. Зашёл в калькулятор один доход. Как только подключился, смотрю, доход другой, конечно, в меньшую сторону...(( Ну я ещё посотрю, как дальше будет.',
      rating: 'assets/img/reviews/4_stars.svg',
      ratingNumber: '4.0'
   },
   {
      id: 5,
      author: 'Дмитрий Николаевич',
      date: 'Август 10, 2023',
      text: 'У меня самые лучшие впечатления от работы с IQL. Работать в крипте начал недавно, увидел рекламу. Поэтому очень боялся, что попадусь на разводку. Но профессиональное качество сайта и службы поддержки меня расположили положительно. Сразу помогли в покупке, настройке и прошивке. Видно, что команда очень опытная, поддержка...',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 6,
      author: 'RACER',
      date: 'Август 18, 2023',
      text: 'С компанией IQL работаю с ними уже почти год, всем доволен, всегда помогут, объяснят. Понравилось, что очень мне помогли правильно подобрать асик, потому что я в этом вообще ничего не понимал) Теперь уже, конечно хорошо разбираюсь.',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 7,
      author: 'Анатолий Короткиев',
      date: 'Октябрь 29, 2023',
      text: 'Цены ниже средних по рынку, нашёл здесь очень выгодные предложения для себя, большой выбор асиков. Хороший сайт для отслежки майна, все очень удобно и комфортно. Проверил вывод средств в первый же день майнинга, т.к. много кидалова сейчас по инету, у этих ребят всё работает как часы.',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 8,
      author: 'Валентин',
      date: 'Февраль 22, 2024',
      text: 'Перевел сюда мощности, чтобы протестировать. Пока результаты плюс-минус такие же, как и на других пулах. Но зато большое количество монет для майнинга, русскоязычный саппорт, от которого недолго ждать ответа и выплаты любых сумм. Думаю остаться у них в текущей ситуации, когда непонятно, что ждать от других криптосервисов.',
      rating: 'assets/img/reviews/4_stars.svg',
      ratingNumber: '4.0'
   },
   {
      id: 9,
      author: 'Эдуард Старцев',
      date: 'Декабрь 31, 2023',
      text: 'Подключился к IQL-пулу в первый раз. Кайфую, что всё на русском). Удалось сделать все правильно с первого раза. Сейчас вроде как все работает нормально, первые выплаты уже получил. Будем смотреть, как будет дальше, но пока впечатления только положительные.',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 10,
      author: 'Токсово Тур',
      date: 'Декабрь 19, 2023',
      text: 'Регистрация в IQL простая, через эл.почту. Для работы на надо никаких верификаций и прочей лабуды. Выводит всё четко и точно как часы. За вывод комиссий нет. Я доволен',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 11,
      author: 'Территория Pattro',
      date: 'Ноябрь 03, 2023',
      text: 'Самое главное поддержка работает очень быстро) Не ну были косяки за 3 месяца, пару раз СМСки не приходили, пару раз приложение тупило. Все устранялось от 1ч до 4 часов, и все работало дальше нормально) и поддержка адекватно реагировала на все обращения)',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
   {
      id: 12,
      author: 'Сергей Витальевич',
      date: 'Ноябрь 06, 2023',
      text: 'Новые ребята ворвались в СНГ))))) Тех. поддержкой очень доволен, ребята работают, а не делают вид. Хотя не знаю ни одной площадки где все было бы гладко и ровно. 2 раза просто не мог зайти - решение было в течении часа - шото с обновлениями связанно было, из минусов - не предупредили нигде о технических работах',
      rating: 'assets/img/reviews/5_stars.svg',
      ratingNumber: '5.0'
   },
]

const reviewOpen = document.querySelectorAll('.review .more')

const authorRev = document.querySelector('.popup-review .author__name')
const dateRev = document.querySelector('.popup-review time')
const textRev = document.querySelector('.popup-review .text')
const imgRev = document.querySelector('.popup-review .info img')
const rateNumberRev = document.querySelector('.popup-review .info .num')

reviewOpen.forEach(more => {
   more.addEventListener('click', function() {
      let attrRev = Number(more.getAttribute('data-id'))
      let currentRev = dataReviews.find(rev => rev.id === attrRev)
      
      let { author, date, text, rating, ratingNumber } = currentRev
      authorRev.innerHTML = author
      dateRev.innerHTML = date
      textRev.innerHTML = text
      imgRev.src = rating
      rateNumberRev.innerHTML = ratingNumber
   })
})

new openPopup({
   selector: '#popup-review',
   elOpen: '.review .more',
   elClose: '#popup-review .ui-close',
   overlay: '#popup-review .popup-overlay',
})


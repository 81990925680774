import Swiper from 'swiper/bundle';
import '../../popups/popup-review/popup-review.js';

const swiperReviews = new Swiper('#swiper-reviews', {
   slidesPerGroup: 1,
   loop: false,
   // centeredSlides: true,
   initialSlide: 1,
   spaceBetween: 20,
   speed: 300,
   grabCursor: true,
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
      dynamicBullets: true
   },
   navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
   },
   breakpoints: {
      120: {
         slidesPerView: 1.25,
         slidesPerGroup: 1,
         spaceBetween: 20,
         centeredSlides: true
      },
      700: {
         slidesPerView: 2,
         spaceBetween: 20,
         slidesPerGroup: 1,
         centeredSlides: true
      },
      1000: {
         slidesPerView: 3.3,
         spaceBetween: 20,
         slidesPerGroup: 1,
         // centeredSlides: true
      },
      1300: {
         slidesPerView: 3,
         spaceBetween: 20,
         slidesPerGroup: 1,
         // centeredSlides: true,
      }
   }
})

const reviewText = document.querySelectorAll('#swiper-reviews .text')
const fullTextReviews = []

reviewText.forEach((item, index) => {
   let text = item.innerText
   fullTextReviews.push({ id: index + 1, text })

   item.innerText = textMofified(text, 300)
})


function textMofified(text, maxLength) {
   if (text.length > maxLength) 
      return text.slice(0, maxLength) + '...'
   return text
}




import openPopup from '../popup.js';

const formPopup = new openPopup({
   selector: '#popup-form',
   elOpen: '.btn_popup',
   elClose: '#popup-form .ui-close',
   overlay: '#popup-form .popup-overlay',
});

export {
   formPopup
}
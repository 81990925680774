import { formPopup } from '../../../components/popups/popup-form/popup-form.js';
import Notification from '../Notification.js';
import validateForm from './validateForm.js';

export default async function sendForm(form, token) {
   const statusMessage = document.querySelector('.form-wrap .message-status');

   try {
      const isValid = validateForm(form);
      if (isValid) {
         const phpHandlerUrl = "assets/libs/telegram.php";

         const formData = new FormData(form);
         formData.append('g-recaptcha-response', token);

         const response = await fetch(phpHandlerUrl, {
            method: "POST",
            body: formData,
         });

         if (response.ok) {
            for (let item of document.querySelectorAll('#form .field')) {
					item.value = ''
				}

            formPopup.onClose()

            // Notification - успещная отрпавка формы
            const successNote = new Notification({})
            successNote.onOpen()
            
         } else {
            statusMessage.classList.add('error')
            statusMessage.innerText = 'Ошибка. Форма не отправлена'
         }
      }
   } catch (error) {
      statusMessage.classList.add('error')
      statusMessage.innerText = 'Ошибка. Форма не отправлена'
   }
}

